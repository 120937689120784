import {
  SET_LOADING_TRUE,
  SET_LOADING_FALSE,
  GET_ALL_LOOKUPS_SUCCESS,
  GET_ALL_LOOKUPS_FAILURE,
  GET_ALL_LOOKUPS_START,
} from './constants';

const INITIAL_STATE = {
  usersList: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };
    case SET_LOADING_FALSE:
      return {
        ...state,
        loading: false,
      };
    case GET_ALL_LOOKUPS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        fetchLookupsStatus: 'success',
        fetchProjectDetails: true,
        usersList: action.payload,
      };

    case GET_ALL_LOOKUPS_START:
      return {
        ...state,
        loading: true,
        usersList: [],
      };
    case GET_ALL_LOOKUPS_FAILURE:
      return {
        ...state,
        fetchLookupsError: true,
        loading: false,
        fetchLookupsStatus: 'error',
      };

    default:
      return state;
  }
};
