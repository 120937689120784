/* eslint-disable complexity */
import {
  GET_ALL_LOOKUPS_SUCCESS,
  GET_ALL_LOOKUPS_FAILURE,
  GET_ALL_USER_ROLES_RESET,
  GET_ALL_USER_ROLES_SUCCESS,
  GET_ALL_USER_ROLES_FAILURE,
  GET_ALL_USER_ROLES_START,
  RESET_SEARCH_ADD_USER,
  DISABLE_SEARCH_ADD_USER,
  START_SEARCH_ADD_USER,
  SUCCESS_SEARCH_ADD_USER,
  FAILURE_SEARCH_ADD_USER,
  GET_APPROVERS_LIST_FAILURE,
  GET_APPROVERS_LIST_RESET,
  GET_APPROVERS_LIST_START,
  GET_APPROVERS_LIST_SUCCESS,
  GET_PROJECT_APPROVERS_LIST_FAILURE,
  GET_PROJECT_APPROVERS_LIST_RESET,
  GET_PROJECT_APPROVERS_LIST_START,
  GET_PROJECT_APPROVERS_LIST_SUCCESS,
  APPROVALS_RESET,
  APPROVALS_SAVE_FAILURE,
  APPROVALS_SAVE_START,
  APPROVALS_SAVE_SUCCESS,
  APPROVALS_SUBMIT_START,
  APPROVALS_SUBMIT_SUCCESS,
  APPROVALS_SUBMIT_FAILURE,
  APPROVALS_SUBMIT_RESET,
  OVERRIDE_APPROVER_SUCCESS,
  OVERRIDE_APPROVER_FAILURE,
  OVERRIDE_APPROVER_RESET
} from './constants';

const INITIAL_STATE = {
  loading: false,
  error: false,
  approvalEditValues: [],
  messageCode: '',
  alert: '',
  alertMessage: '',
  userAlert: false,
  users: [],
  userEditValues: [],
  approvalUserRoleData: [],
  contactData: [],
  usersListMap: new Map(),
  approversListInfo: {
    message: '',
    messageCode: '',
    loading: false,
  },
  projectApproversListInfo: {
    message: '',
    messageCode: '',
    loading: false,
  },
  projectApprovers: {},
  approvalsData: {
    message: '',
    messageCode: '',
    loading: false,
  },
  approvalsSubmitInfo: {
    message: '',
    messageCode: '',
    loading: false,
    code: ''
  },
  approversListData: [],
  overRideApproverInfo: {
    message: '',
    messageCode: '',
    loading: false,
    code: ''
  }
};

const approvalReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case DISABLE_SEARCH_ADD_USER:
      return {
        ...state,
      };
    case RESET_SEARCH_ADD_USER:
      return {
        ...state,
        error: false,
        messageCode: '',
        addUserSuccess: false,
      };
    case START_SEARCH_ADD_USER:
      return {
        ...state,
        loading: true,
        userEditValues: action.payload,
      };

    case SUCCESS_SEARCH_ADD_USER:
      return {
        ...state,
        userEditValues: action.payload,
        contactData: action.payload,

      };
    case FAILURE_SEARCH_ADD_USER:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case GET_ALL_LOOKUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        contactData: action.payload,
      };

    case GET_ALL_USER_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        approvalUserRoleData: action.payload,
        usersListMap: action.payload,
      };

    case GET_ALL_LOOKUPS_FAILURE:
    case GET_ALL_USER_ROLES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        approvalUserRoleData: action.payload,
        errorCode: action.payload,
      };

    case GET_ALL_USER_ROLES_RESET:
      return {
        ...state,
        loading: false,
        approvalUserRoleData: [],
      };
    case GET_ALL_USER_ROLES_START:
      return {
        ...state,
        loading: true,
        approvalUserRoleData: [],
      };
    case GET_APPROVERS_LIST_START:
      return {
        ...state,
        approversListInfo: {
          message: '',
          messageCode: '',
          loading: true,
        },
        loading: true,
      };
    case GET_APPROVERS_LIST_RESET:
      return {
        ...state,
        approversListInfo: {
          message: '',
          messageCode: '',
          loading: false,
        },
        approversListData: [],
      };
    case GET_APPROVERS_LIST_SUCCESS:
      return {
        ...state,
        approversListInfo: {
          message: 'success',
          loading: false,
          messageCode: '',
        },
        approversListData: action.payload,
        loading: false,
      };
    case GET_APPROVERS_LIST_FAILURE:
      return {
        ...state,
        approversListInfo: {
          response: action.payload,
          loading: false,
        },
        loading: false,
      };
    case GET_PROJECT_APPROVERS_LIST_START:
      return {
        ...state,
        projectApproversListInfo: {
          message: '',
          messageCode: '',
          loading: true,
        },
        loading: true,
      };
    case GET_PROJECT_APPROVERS_LIST_RESET:
      return {
        ...state,
        projectApproversListInfo: {
          message: '',
          messageCode: '',
          loading: false
        },
        projectApprovers: {}
      };
    case GET_PROJECT_APPROVERS_LIST_SUCCESS:
      return {
        ...state,
        projectApproversListInfo: {
          message: 'success',
          loading: false,
          messageCode: '',
        },
        projectApprovers: action.payload,
        loading: false,
      };
    case GET_PROJECT_APPROVERS_LIST_FAILURE:
      return {
        ...state,
        projectApproversListInfo: {
          response: action.payload,
          loading: false,
        },
        loading: false,
      };
    case APPROVALS_RESET:
      return {
        ...state,
        approvalsData: {
          message: '',
          messageCode: '',
          loading: false,
        },
      };
    case APPROVALS_SAVE_SUCCESS:
      return {
        ...state,
        approvalsInfoData: action.payload,
        approvalsData: {
          message: 'success',
          loading: false,
          messageCode: '',
        },
        loading: false,
      };
    case APPROVALS_SAVE_FAILURE:
      return {
        ...state,
        approvalsData: {
          ...action.payload,
          loading: false,
        },
        loading: false,
      };
    case APPROVALS_SAVE_START:
      return {
        ...state,
        approvalsData: {
          message: '',
          messageCode: '',
          loading: true,
        },
        loading: true,
      };
    case APPROVALS_SUBMIT_START:
      return {
        ...state,
        approvalsSubmitInfo: {
          message: '',
          messageCode: '',
          loading: true
        },
        loading: true
      };

    case APPROVALS_SUBMIT_SUCCESS:
      return {
        ...state,
        approvalsSubmitInfo: {
          message: 'success',
          messageCode: '',
          loading: false
        },
        loading: false
      };

    case APPROVALS_SUBMIT_FAILURE:
      return {
        ...state,
        approvalsSubmitInfo: {
          message: 'failure',
          messageCode: action.payload.messageCode,
          loading: false,
          code: action.payload?.code
        },
        loading: false
      };

    case APPROVALS_SUBMIT_RESET:
      return {
        ...state,
        approvalsSubmitInfo: {
          message: '',
          messageCode: '',
          loading: false
        },
        loading: false
      };
    
      case OVERRIDE_APPROVER_SUCCESS:
        return {
          ...state,
          overRideApproverInfo: {
            message: 'success',
            messageCode: '',
            loading: false
          },
          loading: false
        };
  
      case OVERRIDE_APPROVER_FAILURE:
        return {
          ...state,
          overRideApproverInfo: {
            message: 'failure',
            messageCode: action.payload.messageCode,
            loading: false
          },
          loading: false
        };

      case OVERRIDE_APPROVER_RESET:
        return {
          ...state,
          overRideApproverInfo: {
            message: '',
            messageCode: '',
            loading: false
          },
          loading: false
        };

    default:
      return state;
  }
};
export default approvalReducer;
