import React, { useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import IntlMessages from 'util/IntlMessages';
import { useHistory } from 'react-router';
import Typography from '@material-ui/core/Typography';
import { Button, DialogContentText } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import { DialogActions, DialogTitle } from '../components/DialogBox';
import { UserHierarchyAccessContext } from 'containers/Services/UserHierarchyAccessProvider';
import { getRedirectPath, clearRedirectPath } from 'app/Utils/sessionStorageUtils';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      maxWidth: '100%',
      width: '100%',
    },
    boxsegment: {
      width: '92%',
      padding: '20% 20%',
      border: '1px solid rgba(0, 0, 0, 0.12) !important;',
      boxSizing: 'border-box',
      boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.14) !important',
      textAlign: 'center',
      paddingInline: '5%',
    },
    buttonoutline: {
      border: '1px solid #003F2D',
      color: '#003F2D',
    },
    buttoncontained: {
      background: '#003F2D',
    },
    headingsegment: {
      fontSize: '24px',
      lineHeight: '32px',
    },
    segmentheading: {
      color: 'rgba(33, 33, 33, 0.3) !important',
      fontWeight: '500',
      fontSize: '20px',
      lineHeight: '27px',
    },
    segmenttext: {
      width: '94%',
      paddingLeft: '16px',
      fontSize: '16px',
      lineHeight: '25px',
      marginTop: '3%',
      fontWeight: '500',
    },
    segmentroot: {
      width: '32%',
      marginBottom: '3%',
      position: 'relative',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    segmentactive: {
      backgroundColor: '#ffffff',
      width: '92%',
      paddingTop: '60px',
      height: '150px',
      border: '1px solid #003F2D;',
      boxSizing: 'border-box',
      boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.14)',
      textAlign: 'center',
      paddingInline: '5%',
    },
    activeheading: {
      color: '#003F2D',
      fontWeight: '500',
      fontSize: '20px',
      lineHeight: '27px',
    },
    iconsegment: {
      position: 'absolute',
      top: '-15px',
      right: '10px',
      background: '#ffffff',
    },
    noAccessActions: {
      padding: '0 30px',
      marginTop: '10px',
    },
  }),
);

const SegmentSelectorDialog = (props) => {
  const { projectOnlyAccess, open, handleCloseDialog, firstStep } = props;
  const { segmentArray, selectedSegment, handleSegmentChange, isAccessLoading } = useContext(
    UserHierarchyAccessContext,
  );
  const { isSystemAdmin } = useSelector((state) => state.auth);
  const [dialogOpen, setDialogOpen] = useState(true);
  const [hasNoAccess, setHasNoAccess] = useState(true);
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (!firstStep) {
      setDialogOpen(true)
      setHasNoAccess(true)
    }
  }, [open, firstStep]);

  useEffect(() => {
    if (!isAccessLoading) {
      if (!segmentArray || segmentArray.length === 0) {
        setHasNoAccess(true);
        setDialogOpen(false);
      } else if (
        segmentArray.length === 1
      ) {
        if (firstStep) {
          const path = `/myprojects`;
          history.push(path);
        }
      }
    }
  }, [segmentArray, isAccessLoading]);

  const handleNext = () => {
    const redirectUrl = getRedirectPath() || '/myprojects';
    clearRedirectPath();
    history.push(redirectUrl);
    setDialogOpen(false);
  };

  const handleClose = () => {
    setDialogOpen(false);
    handleSegmentChange(segmentArray.map((segment) => ({ ...segment, isSelected: false })));
    sessionStorage.removeItem('selectedSegment');
    if (firstStep) {
      const path = '/dashboard';
      history.push(path);
    }
  };

  const closeNoAccessDialog = () => {
    setHasNoAccess(false);
    if (firstStep) {
      const path = '/dashboard';
      history.push(path);
    }
  };

  const closeNoAccessDialogSamePage = () => {
    setHasNoAccess(false);
    setDialogOpen(false);
    handleCloseDialog()
  };

  const openServiceNowTicket = () => {
    setHasNoAccess(false);
    window.open('https://cbre.service-now.com/nav_to.do?uri=%2fcom.glideapp.servicecatalog_cat_item_view.do%3Fv%3D1%26sysparm_id%3D09453fba1bfae5d8a14d844fdd4bcb6b', '_blank');
    if (firstStep) {
      const path = '/dashboard';
      history.push(path);
    }
  };

  const selectSegment = (id) => {
    const updatedSegments = segmentArray.map((segment) => {
      if (segment.id === id) {
        return { ...segment, isSelected: true };
      } else {
        return { ...segment, isSelected: false };
      }
    });
    handleSegmentChange(updatedSegments);
  };

  let dialogContent;
  if (segmentArray === undefined || isAccessLoading) {
    dialogContent = null;
  } else if (firstStep && segmentArray.length > 1) {
    dialogContent = (
      <Dialog
        open={dialogOpen}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
        style={{ zIndex: '2300' }}
        className={classes.root}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle onClose={handleClose}>
          <Typography
            gutterBottom={true}
            variant="h5"
            className={classes.headingsegment}
          >
            <IntlMessages id="PDA.segment.heading" />
          </Typography>
        </DialogTitle>
        <DialogContentText>
          <Box display="flex" justifyContent="center">
            {segmentArray.map((segment) => (
              <Box
                key={segment.id}
                className={`${segment?.isDisable ? 'disable-block' : ''} ${classes.segmentroot
                  }`}
                onClick={() => !segment?.isDisable && selectSegment(segment.id)}
              >
                {segment?.isSelected && (
                  <Box className={classes.iconsegment}>
                    <CheckCircleIcon htmlColor="#003F2D" />
                  </Box>
                )}
                <Box
                  className={
                    !segment?.isDisable
                      ? classes.segmentactive
                      : classes.boxsegment
                  }
                >
                  <Typography
                    className={
                      !segment?.isDisable
                        ? classes.activeheading
                        : classes.segmentheading
                    }
                  >
                    {segment.title}
                  </Typography>
                </Box>
                {segment?.isDisable && (
                  <Typography className={classes.segmenttext}>
                    <IntlMessages id="PDA.segment.dialogbox.subheading4" />
                  </Typography>
                )}
              </Box>
            ))}
          </Box>
        </DialogContentText>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
            className={classes.buttonoutline}
          >
            <span>
              <IntlMessages id="PDA.segment.dialogbox.cancelbutton" />
            </span>
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            className={classes.buttoncontained}
            disabled={!selectedSegment}
          >
            <span>
              <IntlMessages id="PDA.segment.dialogbox.nextbutton" />
            </span>
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else if (segmentArray.length === 0) {
    dialogContent = (
      <Dialog
        open={hasNoAccess}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            closeNoAccessDialog();
          }
        }}
        style={{ zIndex: '2300' }}
        className={classes.root}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle onClose={closeNoAccessDialog}>
          <Typography
            gutterBottom
            variant="h5"
            className={classes.headingsegment}
          >
            <IntlMessages id="PDA.segment.noDataHeading" />
          </Typography>
        </DialogTitle>
        <DialogContentText>
          <Typography variant="h6" className={classes.segmenttext}>
            <IntlMessages id="PDA.segment.noDataMessage" />
          </Typography>
        </DialogContentText>
        <DialogActions className={classes.noAccessActions}>
          <Button
            variant="outlined"
            color="primary"
            onClick={closeNoAccessDialog}
            className={classes.buttonoutline}
          >
            <span>
              <IntlMessages id="PDA.segment.dialogbox.cancelbutton" />
            </span>
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={openServiceNowTicket}
            className={classes.buttoncontained}
          // disabled={!selectedSegment}
          >
            <IntlMessages id="PDA.segment.fillMessage" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else if (open && projectOnlyAccess && !isSystemAdmin) {
    dialogContent = (
      <Dialog
        open={hasNoAccess}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            closeNoAccessDialogSamePage();
          }
        }}
        style={{ zIndex: '2300' }}
        className={classes.root}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle onClose={closeNoAccessDialogSamePage}>
          <Typography
            gutterBottom
            variant="h5"
            className={classes.headingsegment}
          >
            <IntlMessages id="PDA.segment.noDataHeading" />
          </Typography>
        </DialogTitle>
        <DialogContentText>
          <Typography variant="h6" className={classes.segmenttext}>
            <IntlMessages id="PDA.segment.noProjectCreationAccess" />
          </Typography>
        </DialogContentText>
        <DialogActions className={classes.noAccessActions}>
          <Button
            variant="outlined"
            color="primary"
            onClick={closeNoAccessDialogSamePage}
            className={classes.buttonoutline}
          >
            <span>
              <IntlMessages id="PDA.segment.dialogbox.cancelbutton" />
            </span>
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={openServiceNowTicket}
            className={classes.buttoncontained}
          // disabled={!selectedSegment}
          >
            <IntlMessages id="PDA.segment.fillMessage" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <div>
      {dialogContent}
    </div>
  );
};
export default SegmentSelectorDialog;
